
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_subsidy.cashDisbursement') }}</h4>
            </template>
            <template v-slot:body>
                <b-overlay :show="loading">
                  <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="fiscal_year_id"
                        >
                        <template v-slot:label>
                          {{$t('dae_subsidy.fiscal_year')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalYearList"
                            id="fiscal_year_id"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="circular_type"
                        >
                        <template v-slot:label>
                          {{$t('dae_subsidy.circular_ty')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_type"
                            :options="circularTypeList"
                            id="circular_type"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="circular_id"
                        >
                        <template v-slot:label>
                          {{$t('dae_subsidy.circular_name')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_id"
                            :options="circularList"
                            id="circular_id"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
              </b-overlay>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_subsidy.cashDisbursementList') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(total_farmer)="data">
                                          {{ $n(data.item.total_farmer, {useGrouping: false}) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          {{ getStatus(data.item.status) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-payroll @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-payroll" size="xl" :title="$t('dae_subsidy.cashDisbursement')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Payroll :item="item" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Payroll from './Payroll'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { upazillaApprovedPayroll } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Payroll
  },
  data () {
    return {
      search: {
        circular_type: 0,
        circular_id: 0,
        fiscal_year_id: 0
      },
      item: '',
      circularList: [],
      editItemId: 0
    }
  },
  computed: {
      fiscalYearList () {
      const list = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return list.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
      },
      formTitle () {
      return (this.editItemId === 0) ? this.$t('dae_subsidy.distribution') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_subsidy.distribution') + ' ' + this.$t('globalTrans.modify')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('dae_subsidy.fiscal_year'), class: 'text-center' },
              { label: this.$t('dae_subsidy.circular_type'), class: 'text-center' },
              { label: this.$t('dae_subsidy.circular'), class: 'text-center' },
              { label: this.$t('dae_subsidy.total_farmer'), class: 'text-center' },
              { label: this.$t('financialAid.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                  { key: 'index' },
                  { key: 'fiscal_year_name_bn' },
                  { key: 'circular_type_name_bn' },
                  { key: 'circular_name_bn' },
                  { key: 'total_farmer' },
                  { key: 'status' },
                  { key: 'action' }
              ]
          } else {
              keys = [
                  { key: 'index' },
                  { key: 'fiscal_year_name' },
                  { key: 'circular_type_name' },
                  { key: 'circular_name' },
                  { key: 'total_farmer' },
                  { key: 'status' },
                  { key: 'action' }
              ]
          }
          return labels.map((item, index) => {
          return Object.assign(item, keys[index])
          })
      },
      circularTypeList () {
          return this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
      }
  },
  watch: {
      loadingState: function (newVal, oldVal) {
          if (newVal) {
              this.loadData()
          }
      },
      'search.circular_type': function (newValue) {
          this.circularList = this.getCircularName(newValue)
      }
  },
  created () {
      this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
        this.item = item
    },
    async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        await RestApi.getData(incentiveGrantServiceBaseUrl, upazillaApprovedPayroll, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    getRelatinalData (data) {
        const circularList = this.$store.state.incentiveGrant.commonObj.circularList
        const subsidyCircularTypeList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList
        const listData = data.map(item => {
            const circularObj = circularList.find(circular => circular.value === item.circular_id)
            const circularTypeObj = subsidyCircularTypeList.find(ct => ct.value === circularObj.circular_type)
            const fiscalYearObject = fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id)

            const circularTypeData = {
                circular_type_name: circularTypeObj !== undefined ? circularTypeObj.text_en : '',
                circular_type_name_bn: circularTypeObj !== undefined ? circularTypeObj.text_bn : ''
            }
            const circularData = {
                circular_name: circularObj !== undefined ? circularObj.text_en : '',
                circular_name_bn: circularObj !== undefined ? circularObj.text_bn : ''
            }
            const fiscalYearData = {
                fiscal_year_name: fiscalYearObject !== undefined ? fiscalYearObject.text_en : '',
                fiscal_year_name_bn: fiscalYearObject !== undefined ? fiscalYearObject.text_bn : ''
            }

            return Object.assign({}, item, circularTypeData, circularData, fiscalYearData)
        })
        return listData
    },
    getCircularName (circularType) {
        const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.type === circularType)
        return circularList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('financialAid.paid')
      }
    }
  }
}
</script>
