<template>
  <b-container fluid>
    <b-overlay :show="loading">
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col sm="12" md="6" lg="6" xl="6" xs="12">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="division_id"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.division')}}
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.division_id"
                                    :options="divisionList"
                                    id="division_id"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6" lg="6" xl="6" xs="12">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="district_id"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.district')}}
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.district_id"
                                    :options="districtList"
                                    id="district_id"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6" lg="6" xl="6" xs="12">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="upazilla_id"
                                >
                                <template v-slot:label>
                                    {{$t('org_pro_upazilla.upazilla')}}
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.upazilla_id"
                                    :options="upazillaList"
                                    id="upazilla_id"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6" lg="6" xl="6" xs="12">
                                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <template>
                                    <div style="font-size:12px; background-color: #337982;">
                                        <h5 class="text-white text-center"> {{ $t('admission_form.gen_inf') }}</h5>
                                    </div>
                                </template>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.fiscal_year') }}</b-th>
                                        <b-td> {{ locale === 'en' ? payroll.fiscal_year_name : payroll.fiscal_year_name_bn }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.circular_ty') }}</b-th>
                                        <b-td> {{ locale === 'en' ? payroll.circular_type_name : payroll.circular_type_name_bn }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.circular_name') }}</b-th>
                                        <b-td> {{ locale === 'en' ? payroll.circular_name : payroll.circular_name_bn }} </b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <template>
                                  <div style="font-size:12px; background-color: #337982;">
                                    <h5 class="text-white text-center"> {{ $t('dae_subsidy.farmer_info') }}</h5>
                                  </div>
                                </template>
                                <div v-for="(applicant, indx) in applicants" :key="indx" class="mt-3">
                                    <h5>{{ $t('globalTrans.division') }}: {{ $i18n.locale === 'bn' ? applicant.division_name_bn : applicant.division_name }}, {{ $t('globalTrans.district') }}: {{ $i18n.locale === 'bn' ? applicant.district_name_bn : applicant.district_name }}, {{ $t('globalTrans.upazila') }}: {{ $i18n.locale === 'bn' ? applicant.upazilla_name_bn : applicant.upazilla_name }} </h5>
                                    <b-table-simple striped bordered small class="mt-2">
                                        <b-tr class="text-center">
                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th>{{ $t('globalTrans.name') }}</b-th>
                                            <b-th>{{ $t('dae_grant_allocation_distribution.farmer_father_name') }}</b-th>
                                            <b-th>{{ $t('dae_subsidy.nid') }}</b-th>
                                            <b-th>{{ $t('globalTrans.mobile') }}</b-th>
                                            <b-th>{{$t('common_config.account_type')}}</b-th>
                                            <b-th>{{$t('common_config.account_no')}}</b-th>
                                            <b-th>{{$t('bsri_demandDIS.bank')}}</b-th>
                                            <b-th>{{$t('bsri_demandDIS.branch')}}</b-th>
                                            <b-th>{{$t('dae_subsidy.routing')}}</b-th>
                                            <b-th>{{$t('rehabilitaionAllocation.cash_tk')}}</b-th>
                                        </b-tr>
                                        <b-tr v-for="(detail,index) in applicant.farmers" :key="index">
                                            <b-td class="text-center">{{ $n(index+1) }}</b-td>
                                            <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_name_bn : detail.farmer_name }}</b-td>
                                            <b-td>{{ ($i18n.locale === 'bn') ? detail.father_name_bn : detail.father_name }}</b-td>
                                            <b-td>{{ $n(detail.nid_no, { useGrouping: false }) }}</b-td>
                                            <b-td>{{ ($i18n.locale === 'bn' ? '০' : '0')}}{{ $n(detail.mobile_no, { useGrouping:false}) }}</b-td>
                                            <b-td>{{ detail.account_type_name }}</b-td>
                                            <b-td>{{ detail.ac_category_id == 1 ? ($i18n.locale === 'bn' ? '০' : '0') + $n( detail.wallet_no, { useGrouping:false}) : $n(detail.account_no, { useGrouping:false}) }}</b-td>
                                            <b-td>{{ detail.ac_category_id == 1 ? detail.ac_type_name : detail.bank_name }}</b-td>
                                            <b-td>{{ detail.branch_name ? detail.branch_name : '-'}}</b-td>
                                            <b-td> {{ detail.routing_no ? detail.routing_no : '-'}}</b-td>
                                            <b-td class="text-center">{{ $n(detail.cash_tk, { useGrouping: false }) }}</b-td>
                                        </b-tr>
                                        <b-tr v-if="applicant.farmers.length !== 0" style="font-weight:bold">
                                            <b-td colspan="10" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                            <b-td class="text-center">{{ $n(grandTotal, { useGrouping: false }) }}</b-td>
                                        </b-tr>
                                        <b-tr v-if="applicant.farmers.length === 0">
                                            <b-td cols="10" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                        </b-tr>
                                    </b-table-simple>
                                </div>
                                <div v-if="applicants.length === 0">
                                    <p class="text-center">{{ $t('globalTrans.noDataFound') }}</p>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <div class="text-right">
                                    <b-button type="submit" variant="primary" class="mr-2" @click="save" v-if="payroll.status === 1">{{ $t('dae_subsidy.submitPayroll') }}</b-button>
                                        &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-payroll')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { getPayrollFarmer, districtPrepare } from '../../api/routes'
export default {
    props: ['item'],
    data () {
        return {
            grandTotal: 0,
            payroll: {},
            applicants: [],
            loading: false,
            fiscalYear: '',
            districtList: [],
            upazillaList: [],
            search: {
                division_id: 0,
                district_id: 0,
                upazilla_id: 0
            },
            accountTypeList: [
              { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
              { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
            ]
        }
    },
    created () {
        if (this.item) {
            this.payroll = this.item
            this.getApplicantList()
        }
    },
    watch: {
        'search.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'search.district_id': function (newVal, oldVal) {
            this.upazillaList = this.getUpazilaList(newVal)
        }
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        divisionList () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        }
    },
    methods: {
        searchData () {
            this.getApplicantList()
        },
        getApplicantList () {
            this.loading = true
            const params = Object.assign(this.search, { circular_id: this.item.circular_id })
            RestApi.getData(incentiveGrantServiceBaseUrl, getPayrollFarmer, params).then(response => {
                if (response.success) {
                    this.applicants = this.farmerRelationalData(response.data)
                } else {
                    this.applicants = []
                }
                this.loading = false
            })
        },
        farmerRelationalData (data) {
            return data.map((tmp, index) => {
                const upazilla = this.$store.state.commonObj.upazilaList.find(upz => upz.value === tmp.upazillaId)
                const district = this.$store.state.commonObj.districtList.find(dist => dist.value === upazilla.district_id)
                const division = this.$store.state.commonObj.divisionList.find(div => div.value === district.division_id)
                const upazillaObj = {}
                upazillaObj.upazilla_name = upazilla !== undefined ? upazilla.text_en : ''
                upazillaObj.upazilla_name_bn = upazilla !== undefined ? upazilla.text_bn : ''

                const districtObj = {}
                districtObj.district_name = district !== undefined ? district.text_en : ''
                districtObj.district_name_bn = district !== undefined ? district.text_bn : ''
                const divisionObj = {}

                divisionObj.division_name = division !== undefined ? division.text_en : ''
                divisionObj.division_name_bn = division !== undefined ? division.text_bn : ''

                tmp.farmers = tmp.farmers.map(item => {
                this.grandTotal += item.cash_tk
                    const deReqObj = {
                        far_general_info_id: item.far_general_info_id,
                        farmer_name: item.name,
                        farmer_name_bn: item.name_bn,
                        father_name: item.father_name,
                        father_name_bn: item.father_name_bn,
                        nid_no: item.nid_no,
                        mobile_no: item.mobile_no
                    }
                    const accountTypeData = this.accountTypeList.find(type => type.value === item.ac_category_id)
                    const accountGroupData = { account_type_name: accountTypeData !== undefined ? (this.locale === 'bn' ? accountTypeData.text_bn : accountTypeData.text_en) : '' }
                    const acTypeName = this.$store.state.commonObj.bankObj.bankList.find(acType => acType.value === item.ac_type_id)
                    const acTypeData = { ac_type_name: acTypeName !== undefined ? (this.locale === 'bn' ? acTypeName.text_bn : acTypeName.text_en) : '' }
                    const bankObj = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === item.bank_id)
                    const bankData = { bank_name: bankObj !== undefined ? (this.locale === 'bn' ? bankObj.text_bn : bankObj.text_en) : '' }
                    const branchObj = this.$store.state.commonObj.bankObj.branchList.find(branch => branch.value === item.branch_id)
                    const branchData = { branch_name: branchObj !== undefined ? (this.locale === 'bn' ? branchObj.text_bn : branchObj.text_en) : '' }
                    return Object.assign({}, item, deReqObj, accountGroupData, acTypeData, bankData, branchData)
                })
                return Object.assign({}, tmp, upazillaObj, districtObj, divisionObj)
            })
        },
        async save () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, `${districtPrepare}/${this.item.circular_id}`)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-payroll')
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: result.message,
                    color: '#fff'
                })
            }
        },
        getDistrictList (divisionId = null) {
            return this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === divisionId)
        },
        getUpazilaList (districtId = null) {
            return this.$store.state.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === districtId)
        }
    }
}
</script>
